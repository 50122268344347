import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import EmptyState from '../../static/empty-state.png'

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <ErrorContainer>
            <h1>ERROR 404</h1>
            <h2>Something went South</h2>
            <img src={EmptyState} alt="404 illustration" />
            <Link to="/">TAKE A STEP BACK</Link>
        </ErrorContainer>
    </Layout>
)

const ErrorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    margin: 0 auto;

    h1 {
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1.39px;
        line-height: 16px;
        opacity: 0.5;
        margin-bottom: 4px;
    }

    h2 {
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        text-align: center;
        letter-spacing: 0.14px;
        line-height: 28px;
        margin-bottom: 20px;
    }

    a {
        display: flex;
        margin-top: 35px;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        background: white;
        height: 36px;
        width: 232px;
        text-decoration: none;
        border: none;
        font-size: 12px;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #141414;
        transition: 0.3s ease-in-out;
        z-index: 2;
        transition: all 0.3s ease-in-out;

        &:hover {
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            background: #559dd4;
            color: white;
        }
    }

    @media screen and (max-width: 672px) {
        h1 {
            font-size: 12px;
            letter-spacing: 1.04px;
            line-height: 16px;
        }

        h2 {
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 24px;
            margin-bottom: 10px;
        }

        a {
            margin-top: 20px;
        }
    }
`

export default NotFoundPage
